.App {
  font-family: 'bariol_regular';
  text-align: center;
}

@font-face {
  font-family: 'bold__stylish_calligraphyRg';
  src: url(./assets/fonts/bold__stylish_calligraphy-webfont.woff2) format('woff2'),
       url(./assets/fonts/bold__stylish_calligraphy-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dancing_scriptregular';
  src: url(./assets/fonts/dancing_script-webfont.woff2) format('woff2'),
       url(./assets/fonts/dancing_script-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bariol_regular';
  src: url(./assets/fonts/bariol_regular-webfont.woff2) format('woff2'),
       url(./assets/fonts/bariol_regular-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}